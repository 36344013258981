* { 
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html { 
  background-color: #fff; 
  font-family: "Roboto","Helvetica","Arial",sans-serif;
} 
body {
  height: 100vh;
  background-color: whitesmoke; 
  margin: 0 auto;
  overflow: hidden;
}
#root {
  height: 100%;
}
